import { Box } from '@/components/box';
import { Container } from '@/components/container';
import { Layout } from '@/components/layout';
import { CapsizedText as Text } from '@/components/text';
import { VisuallyHidden } from '@/components/visually-hidden';
import { styled } from '@/stitches.config';
import Link from 'next/link';

export const HomeLink = styled(Text, {
  display: 'inline-block',
  textDecoration: 'none',
  color: '$foreground',
  margin: '$6',
  padding: '$2',
  borderBottom: '2px solid transparent',
  '&:hover': {
    borderBottom: '2px solid $foreground',
  },
  '&::after': {
    content: '>',
    marginLeft: '.5em',
  },
});

function NotFoundPage() {
  return (
    <Layout
      title="404"
      ogImage="https://cleverfranke.com/api/og?title=404 not found"
    >
      <Container
        css={{
          display: 'flex',
          textAlign: 'center',
          // Top padding is consistent with the spacing we use sitewise
          // Bottom padding is a visual alignment to allow the footer text to show without being cropped off
          padding: '$13 0 calc($12 + $5) 0',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Text
            as="h1"
            size={8}
            css={{
              marginBottom: '$8',
            }}
          >
            <VisuallyHidden>404</VisuallyHidden>
            <span aria-hidden="true">4&deg;4</span>
          </Text>
          <Text
            as="p"
            size={7}
            css={{
              marginBottom: '$4',
            }}
          >
            It&rsquo;s a bit chilly here.
          </Text>

          <Link href="/" passHref>
            <HomeLink as="a">Bring me home</HomeLink>
          </Link>
        </Box>
      </Container>
    </Layout>
  );
}

export default NotFoundPage;
